import React, { useContext } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import CurrencySwitcher from '~/components/currencySwitcher';
import LangSwitcher from '~/components/langSwitcher';
import SearchGoogleInput from '~/components/searchGoogleInput';
import { getContactPageUrl, getHomePageUrl } from '~/utils/routing';
import Link from 'next/link';
import { Trans } from '@lingui/react';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const TopBar = ({ isHomepage }) => {
  const appCtx = useContext(AppContext);
  // console.log(appCtx.state);

  return (
    <DesktopNav>
      <Link href={`${getHomePageUrl(appCtx)}`} passHref={true}>
        <a>
          <Logo
            alt="logo"
            height="32"
            width="157"
            src={`${publicRuntimeConfig.ASSETS_PREFIX}/images/bs-logo-white.svg`}
          ></Logo>
        </a>
      </Link>

      {!isHomepage && <SearchGoogleInput />}
      <Navigation>
        <Link href={`${getContactPageUrl(appCtx)}`} passHref={true}>
          <NavItem className={isHomepage ? 'isHP' : ''}>
            <Trans id="navigation.contact" />
          </NavItem>
        </Link>
      </Navigation>

      <Switchers>
        {/* <LangSwitcherWrapper className={isHomepage ? 'isHP' : ''}>
          <LangSwitcher />
        </LangSwitcherWrapper> */}
        <CurrencySwitcherWrapper className={isHomepage ? 'isHP' : ''}>
          <CurrencySwitcher />
        </CurrencySwitcherWrapper>
      </Switchers>
    </DesktopNav>
  );
};

const DesktopNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 750px) {
    display: none;
  }
`;

const NavItem = styled.a`
  font-size: 0.875em;
  line-height: 1.714285;
  color: white;
  margin-right: 8px;
  cursor: pointer;
  margin-left: 8px;

  &.isHP {
    font-weight: 500;
  }
`;

const Switchers = styled.div`
  display: flex;
`;

const LangSwitcherWrapper = styled.div`
  width: 60px;
  margin: 0 8px;
  &* > {
    outline: none !important;
  }

  &:focus,
  div[class*='container']:focus,
  div[class*='ValueContainer']:focus {
    /* outline: none !important; */
    /* border: none !important; */
  }

  div[class*='control'] {
    background: transparent;
    border: none !important;
    box-shadow: none !important;
  }

  div[class*='singleValue'] {
    color: white;
    font-size: 0.875em;
    line-height: 1.714285;
    cursor: pointer;
  }

  div[class*='option'] {
    font-size: 0.875em;
    line-height: 1.714285;
    cursor: pointer;
  }

  span[class*='indicatorSeparator'] {
    display: none;
  }

  div[class*='indicatorContainer'] {
    padding: 0px;
    color: white !important;
    cursor: pointer;
  }

  div[class*='ValueContainer'] {
    padding-right: 0px;
    text-align: right;
    justify-content: flex-end;

    input {
      color: transparent !important;
    }
  }

  &.isHP {
    div[class*='singleValue'] {
      font-weight: 500;
    }
  }
`;

const CurrencySwitcherWrapper = styled(LangSwitcherWrapper)`
  width: 75px;
`;

const Logo = styled.img`
  cursor: pointer;
  margin-right: 32px;
  vertical-align: middle;
`;

const Navigation = styled.div`
  margin-left: auto;
  display: flex;
`;
export default TopBar;
